import React, { useState } from "react";
import Lightbox from "react-images";
import {
  Container,
  List,
  SelectMenu,
  Img,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const Item = ({ image }) => (
  <div className="gallery-image">
    <Img src={image} variant="card_horizontal" />
  </div>
);

const variants = {
  2: 6,
  3: 4,
  4: 3,
};

const GalleryImagesWidget = ({ variant, list, containerSettings }) => {
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(0);

  const handlePrevImage = () => {
    if (current > 0) setCurrent(current - 1);
  };

  const handleNextImage = () => {
    if (current < list.length * 2) {
      setCurrent(current + 1);
    }
  };

  return (
    <Container settings={containerSettings}>
      <Lightbox
        images={list.map((i) => ({
          id: i.id,
          src: imageSrc(i.image, "lead"),
        }))}
        isOpen={open}
        currentImage={current}
        onClickPrev={handlePrevImage}
        onClickNext={handleNextImage}
        onClose={() => setOpen(false)}
        enableKeyboardInput
        showCloseButton
        backdropClosesModal
      />

      <div className="gallery-images">
        <div className="container">
          <div className="row">
            {list.map((item, idx) => (
              <div
                key={item.id}
                className={`col-${variants[variant]}`}
                onClick={() => {
                  setOpen(true);
                  setCurrent(idx);
                }}
              >
                <Item {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

GalleryImagesWidget.title = "Gallery";
GalleryImagesWidget.category = "Media";
GalleryImagesWidget.dialogSize = "large";

GalleryImagesWidget.defaults = {
  variant: 3,
  list: [
    { id: 1, image: "http://placehold.it/800x600" },
    { id: 2, image: "http://placehold.it/800x600" },
    { id: 3, image: "http://placehold.it/800x600" },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

GalleryImagesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, "2 columns"],
        [3, "3 columns"],
        [4, "4 columns"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{ image: "http://placehold.it/800x600" }}
      primaryField="id"
      onChangeList={onChange}
    />
  </div>
);

export default GalleryImagesWidget;
