import React from "react";
import { Container, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const LeadImageWidget = ({
  variant,
  image,
  title,
  description,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className={variant}>
      <div
        className="lead-image"
        style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
      >
        <div className="container">
          <div className="content">
            <h1>{title}</h1>
            {description !== "<p></p>" && (
              <div dangerouslySetInnerHTML={{ __html: description }} />
            )}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

LeadImageWidget.title = "Lead image";
LeadImageWidget.category = "Media";
LeadImageWidget.primaryField = "title";
LeadImageWidget.dialogSize = "large";

LeadImageWidget.defaults = {
  variant: "container",
  image: "https://placehold.it/1920x1080",
  title: "Lead image",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>",
  containerSettings: {},
};

LeadImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["container", "Contained"],
      ["full-width", "Full width"],
    ],
  },
  image: { type: "custom", component: ImagePicker },
  title: { type: "text" },
  description: { type: "custom", component: RichText },
};

export default LeadImageWidget;
