import React from "react";
import { Container, List, SelectMenu } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const Item = ({ text }) => (
  <div className="text-column" dangerouslySetInnerHTML={{ __html: text }} />
);

const variants = {
  2: 6,
  3: 4,
  4: 3,
};

const TextColumnsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="text-columns">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={`col-${variants[variant]}`}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

TextColumnsWidget.title = "Text columns";
TextColumnsWidget.category = "General";
TextColumnsWidget.dialogSize = "large";

TextColumnsWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 3,
      text: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <RichText
      label="Text"
      onChange={(value) => onChangeItem("text", value)}
      value={settings.text}
    />
  </div>
);

TextColumnsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="id"
      template={{
        text: "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      }}
    />
  </div>
);

export default TextColumnsWidget;
