import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Item = ({ title, link, image, description }) => (
  <a href={link.link} className="article-card">
    <div className="image">
      <Img src={image} variant="card_horizontal" />
    </div>

    <h4 className="title">
      <div className="text">{title}</div>
      <div className="red-arrow" />
    </h4>

    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
  </a>
);

const ArticleCardsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="article-cards">
      <div className="container">
        <div className={variant}>
          <div className="row">
            {list.map((item) => (
              <div
                key={item.id}
                className={variant === "shifted" ? "col-6" : "col-4"}
              >
                <Item {...item} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </Container>
);

ArticleCardsWidget.title = "Article cards";
ArticleCardsWidget.category = "General";
ArticleCardsWidget.dialogSize = "large";

ArticleCardsWidget.defaults = {
  variant: "shifted",
  list: [
    {
      id: 1,
      title: "Title",
      link: LinkSettings.defaults,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
    {
      id: 2,
      title: "Title",
      link: LinkSettings.defaults,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

ArticleCardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        ["shifted", "Shifted"],
        ["full", "Full-width"],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        title: "Title",
        link: LinkSettings.defaults,
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/800x600",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default ArticleCardsWidget;
