import React from "react";
import Layout from "./frontend/Layout";
import LIBRARY from "./widgets";

const PageRender = ({ page, header_menu, section, footer_menu, gql_url }) => (
  <Layout
    headerMenu={header_menu}
    footerMenu={footer_menu}
    section={section}
    gqlUrl={gql_url}
  >
    {page.widgets.map((widget, idx) =>
      React.createElement(
        LIBRARY[widget.component],
        Object.assign({}, { key: idx }, widget.settings)
      )
    )}
  </Layout>
);

export default PageRender;
