import React, { useState } from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import ColorPicker from "../pickers/ColorPicker";
import Select from "../pickers/Select";

const Item = ({ inlineTheme, link, title, image, description }) => (
  <a href={link.link} className="card">
    <div className="image-wrapper">
      <div
        className="card-image"
        style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
      />
    </div>

    <div className={`card-title inline-theme ${inlineTheme}`}>
      <h4 className="title">
        {title}
        <div className="red-arrow" />
      </h4>
    </div>

    {description !== "<p></p>" && (
      <div
        className="card-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </a>
);

const variants = {
  2: 6,
  3: 4,
};

const listOfValues = (arr) => arr.split(",").map((w) => w.trim());
const flat = (arr) => arr.reduce((acc, val) => acc.concat(val), []);
const uniq = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);
const getTag = (list, key) =>
  uniq(flat(list.map((i) => listOfValues(i[key])))).sort();
const filterJobs = (list, location, type) => {
  if (location && type) {
    return list.filter(
      (i) =>
        listOfValues(i.location).indexOf(location) !== -1 &&
        listOfValues(i.type).indexOf(type) !== -1
    );
  }

  if (location) {
    return list.filter(
      (i) => listOfValues(i.location).indexOf(location) !== -1
    );
  }

  if (type) {
    return list.filter((i) => listOfValues(i.type).indexOf(type) !== -1);
  }

  return list;
};

const JobOffersWidget = ({ variant, inlineTheme, list, containerSettings }) => {
  const [locationFilter, setLocationFilter] = useState(false);
  const [typeFilter, setTypeFilter] = useState(false);

  const jobs = filterJobs(list, locationFilter, typeFilter);

  return (
    <Container settings={containerSettings}>
      <div className="spacing-bottom-sm">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <Select
                prompt="All locations..."
                options={getTag(list, "location").map((t) => [t, t])}
                value={locationFilter}
                onChange={(selectedLocation) =>
                  setLocationFilter(selectedLocation)
                }
              />
            </div>

            <div className="col-4">
              <Select
                prompt="All types..."
                options={getTag(list, "type").map((t) => [t, t])}
                value={typeFilter}
                onChange={(selectedType) => setTypeFilter(selectedType)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="cards">
        <div className="container">
          <div className="row">
            {jobs.length === 0 && (
              <div className="col-8">
                There are no job offers for the selected criteria.
              </div>
            )}

            {jobs.length > 0 &&
              jobs.map((item) => (
                <div key={item.id} className={`col-${variants[variant]}`}>
                  <Item inlineTheme={inlineTheme} {...item} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

JobOffersWidget.title = "Job Offers";
JobOffersWidget.category = "General";
JobOffersWidget.dialogSize = "large";

JobOffersWidget.defaults = {
  variant: 3,
  inlineTheme: "brown",
  list: [
    {
      id: 1,
      title: "Title",
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      location: "",
      type: "",
    },
    {
      id: 2,
      title: "Title",
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      location: "",
      type: "",
    },
    {
      id: 3,
      title: "Title",
      link: LinkSettings.defaults,
      image: "http://placehold.it/800x600",
      location: "",
      type: "",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <TextInput
      label="Location"
      onChange={(value) => onChangeItem("location", value)}
      value={settings.location}
    />

    <TextInput
      label="Type"
      onChange={(value) => onChangeItem("type", value)}
      value={settings.type}
    />
  </div>
);

JobOffersWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <ColorPicker
      value={settings.inlineTheme}
      onChange={(value) => onChange("inlineTheme", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "Title",
        link: LinkSettings.defaults,
        image: "http://placehold.it/800x600",
        location: "",
        type: "",
      }}
    />
  </div>
);

export default JobOffersWidget;
