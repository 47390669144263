import React, { useState } from "react";
import { Container, List, TextInput } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import DownloadForm from "../frontend/DownloadForm";

const DownloadFilesWidget = ({ title, list, containerSettings }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container settings={containerSettings}>
      <div className={`download-files ${open ? "open" : ""}`}>
        <div className="cta-bar">
          <div className="container">
            <div className="row">
              <div className="col-10">
                <div className="cta-bar-inner inline-theme">
                  <div className="cta-text">{title}</div>

                  <button
                    type="button"
                    className="btn-secondary cta-button toggle-category"
                    onClick={() => setOpen(!open)}
                  >
                    See list
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="file-list">
          {list.map((item) => (
            <div key={item.id}>
              <DownloadForm {...item} />
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

DownloadFilesWidget.title = "Downloads";
DownloadFilesWidget.category = "General";
DownloadFilesWidget.dialogSize = "large";

DownloadFilesWidget.defaults = {
  title: "Category",
  list: [
    {
      id: 1,
      title: "File",
      url: "#",
      icon: "http://placehold.it/64x64",
      recaptchaPublicKey: "",
    },
    {
      id: 2,
      title: "File",
      url: "#",
      icon: "http://placehold.it/64x64",
      recaptchaPublicKey: "",
    },
    {
      id: 3,
      title: "File",
      url: "#",
      icon: "http://placehold.it/64x64",
      recaptchaPublicKey: "",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Form URL"
      onChange={(value) => onChangeItem("url", value)}
      value={settings.url}
    />

    <TextInput
      label="Recaptcha public key"
      onChange={(value) => onChangeItem("recaptchaPublicKey", value)}
      value={settings.recaptchaPublicKey}
    />
  </div>
);

DownloadFilesWidget.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="List Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        title: "Title",
        url: "#",
        icon: "http://placehold.it/64x64",
        recaptchaPublicKey: "",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default DownloadFilesWidget;
