import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const PageTitleWidget = ({ title, description, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="page-title">
      <div className="container">
        <div className="row">
          <div className="shift-2">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

PageTitleWidget.title = "Page title";
PageTitleWidget.category = "General";
PageTitleWidget.primaryField = "title";

PageTitleWidget.defaults = {
  title: "Title",
  description:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia asperiores amet molestiae aliquam cumque dignissimos voluptate.</p>",
  containerSettings: {},
};

PageTitleWidget.adminFields = {
  title: { type: "text" },
  description: { type: "custom", component: RichText },
};

export default PageTitleWidget;
