import React from "react";
import { Container } from "@raketa-cms/raketa-cms";

const SecondarySectionTitleWidget = ({ title, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="secondary-section-title">
      <div className="container">
        <div className="secondary-section-title-inner">
          <h3>{title}</h3>
        </div>
      </div>
    </div>
  </Container>
);

SecondarySectionTitleWidget.title = "Secondary section title";
SecondarySectionTitleWidget.category = "General";
SecondarySectionTitleWidget.primaryField = "title";

SecondarySectionTitleWidget.defaults = {
  title: "Title",
  containerSettings: {},
};

SecondarySectionTitleWidget.adminFields = {
  title: { type: "text" },
};

export default SecondarySectionTitleWidget;
