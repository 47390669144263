import gql from 'graphql-tag';

const ARTICLES = gql`
  query($input: ArticlesInput) {
    articles(input: $input) {
      title
      slug
      description
      publishAt
      image {
        name
        width
        height
        alt
        urls {
          original
          image
          lead
          fullscreen
          thumb
          square
          card_horizontal
          card_vertical
          fixed_image
        }
      }
    }
  }
`;

export default ARTICLES;
