import React, { useRef } from 'react';
import Select from '../pickers/Select';

export default ({ filter, url }) => {
  const formRef = useRef(null);

  return (
    <form action={url} ref={formRef} className="spacing-bottom-sm">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <Select
                name="f[industry]"
                prompt="All industries..."
                options={filter.industries.map(i => [i.slug, i.name])}
                value={filter.current_industry_slug || ''}
                onChange={() => formRef.current.submit()}
              />
            </div>

            <Select
              name="f[offering]"
              prompt="All offerings..."
              options={filter.offerings.map(i => [i.slug, i.name])}
              value={filter.current_offering_slug || ''}
              onChange={() => formRef.current.submit()}
            />
          </div>
        </div>
      </div>
    </form>
  );
}
