import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  Img,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Item = ({ title, image, description }) => (
  <div className="feature-list">
    <h4>{title}</h4>

    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}

    {image && <Img src={image} variant="image" />}
  </div>
);

const variants = {
  2: 6,
  3: 4,
  4: 3,
};

const FeatureListWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="feature-lists">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={`col-${variants[variant]}`}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

FeatureListWidget.title = "Feature list";
FeatureListWidget.category = "General";
FeatureListWidget.dialogSize = "large";

FeatureListWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
    {
      id: 2,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
    {
      id: 3,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />
  </div>
);

FeatureListWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "Title",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/800x600",
      }}
    />
  </div>
);

export default FeatureListWidget;
