import React from "react";
import { Container, List, Img } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const Item = ({ image, description }) => (
  <div className="benefit">
    <Img src={image} variant="image" />

    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
  </div>
);

const BenefitsWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="benefits">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className="col-2">
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

BenefitsWidget.title = "Benefits";
BenefitsWidget.category = "General";
BenefitsWidget.dialogSize = "large";

BenefitsWidget.defaults = {
  list: [
    {
      id: 1,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 2,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 3,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 4,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 5,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
    {
      id: 6,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
      image: "http://placehold.it/400x300",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

BenefitsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="id"
      template={{
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. </p>",
        image: "http://placehold.it/400x300",
      }}
    />
  </div>
);

export default BenefitsWidget;
