const queryParamsReducer = (searchParams, paramsPair) => {
  if (paramsPair[0] && paramsPair[1]) {
    searchParams.append(paramsPair[0], paramsPair[1]);
  }

  return searchParams;
};

const buildQueryString = paramsArray => (
  paramsArray.reduce(queryParamsReducer, new URLSearchParams()).toString()
);

const urlWithQuery = (url, queryArray) => {
  const queryString = buildQueryString(queryArray);

  return `${url}?${queryString}`;
};

export { urlWithQuery };
