import React from "react";
import { Container } from "@raketa-cms/raketa-cms";

const SectionTitleWidget = ({ title, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="section-title">
      <div className="container">
        <h2>{title}</h2>
      </div>
    </div>
  </Container>
);

SectionTitleWidget.title = "Section title";
SectionTitleWidget.category = "General";
SectionTitleWidget.primaryField = "title";

SectionTitleWidget.defaults = {
  title: "Section",
  containerSettings: {},
};

SectionTitleWidget.adminFields = {
  title: { type: "text" },
};

export default SectionTitleWidget;
