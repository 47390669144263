import React from 'react';
import { Img } from '@raketa-cms/raketa-cms';
import Layout from '../frontend/Layout';
import TaxonomyFilter from '../frontend/TaxonomyFilter';

const Item = ({ title, slug, image, description }) => (
  <a href={`/customer-success/${slug}`} className="article-card">
    <div className="image">
      {image && (
        <Img src={image} variant="card_horizontal" />
      )}
    </div>

    <h4>{title}</h4>

    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
  </a>
);

export default ({ section, gqlUrl, header_menu, footer_menu, stories, filter_context }) => (
  <Layout section={section} gqlUrl={gqlUrl} headerMenu={header_menu} footerMenu={footer_menu}>
    <div className="spacing-bottom">
      <div className="navigation-title">
        <div className="container">
          <div className="row">
            <div className="col-3 title-wrapper">
              <h5 className="title">Customer Success</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TaxonomyFilter filter={filter_context} url="/customer-success" />

    <div className="article-cards">
      <div className="container">
        <div className="full">
          <div className="row">
            {(stories.length === 0) && (
              <div className="col-12">
                <p>There are no customer success stories for the specified filters. </p>
              </div>
            )}

            {(stories.length > 0) && stories.map((item) =>
              <div key={item.id} className="col-4">
                <Item {...item} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </Layout>
);
