import HtmlWidget from './HtmlWidget';
import SectionTitleWidget from './SectionTitleWidget';
import PageTitleWidget from './PageTitleWidget';
import NavigationTitleWidget from './NavigationTitleWidget';
import SecondarySectionTitleWidget from './SecondarySectionTitleWidget';
import ImageWidget from './ImageWidget';
import VideoWidget from './VideoWidget';
import TextWidget from './TextWidget';
import TextAndImageWidget from './TextAndImageWidget';
import TextColumnsWidget from './TextColumnsWidget';
import SectionLeadWidget from './SectionLeadWidget';
import ProductHeroWidget from './ProductHeroWidget';
import LeadImageWidget from './LeadImageWidget';
import OffceWidget from './OffceWidget';
import FeatureListWidget from './FeatureListWidget';
import BenefitsWidget from './BenefitsWidget';
import IconsAndDescriptionWidget from './IconsAndDescriptionWidget';
import NavigationWidget from './NavigationWidget';
import IntroCardsWidget from './IntroCardsWidget';
import CardsWidget from './CardsWidget';
import ArticleCardsWidget from './ArticleCardsWidget';
import GalleryImagesWidget from './GalleryImagesWidget';
import LeadImageSliderWidget from './LeadImageSliderWidget';
import CtaBarWidget from './CtaBarWidget';
import DownloadFilesWidget from './DownloadFilesWidget';
import JobOffersWidget from './JobOffersWidget';
import CtaLinkWidget from './CtaLinkWidget';
import NewsFeedWidget from './NewsFeedWidget';
import SingleDownloadWidget from './SingleDownloadWidget';
import CustomerNavigationWidget from './CustomerNavigationWidget';

export default {
  HtmlWidget,
  SectionTitleWidget,
  PageTitleWidget,
  NavigationTitleWidget,
  SecondarySectionTitleWidget,
  ImageWidget,
  VideoWidget,
  TextWidget,
  TextAndImageWidget,
  TextColumnsWidget,
  SectionLeadWidget,
  ProductHeroWidget,
  LeadImageWidget,
  OffceWidget,
  FeatureListWidget,
  BenefitsWidget,
  IconsAndDescriptionWidget,
  NavigationWidget,
  IntroCardsWidget,
  CardsWidget,
  ArticleCardsWidget,
  GalleryImagesWidget,
  LeadImageSliderWidget,
  CtaBarWidget,
  DownloadFilesWidget,
  JobOffersWidget,
  CtaLinkWidget,
  NewsFeedWidget,
  SingleDownloadWidget,
  CustomerNavigationWidget,
};
