import React from "react";
import Swiper from "react-id-swiper";
import { Container, List, imageSrc, TextInput } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

class LeadImageSlider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentImageIdx: 0,
    };
  }

  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange)
      this.swiper.on("slideChange", () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  handlePrevImage() {
    const { currentImageIdx } = this.state;

    if (currentImageIdx > 0)
      this.setState({ currentImageIdx: currentImageIdx - 1 });
  }

  handleNextImage() {
    const { list } = this.props;
    const { currentImageIdx } = this.state;

    if (currentImageIdx < list.length)
      this.setState({ currentImageIdx: currentImageIdx + 1 });
  }

  render() {
    const { containerSettings, variant, autoplay, list } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="lead-image-slider">
          <div className="container">
            <Swiper
              ref={(node) => {
                if (node) this.swiper = node.swiper;
              }}
              rebuildOnUpdate
              pagination={{
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true,
              }}
              loop
              keyboard={true}
              paginationClickable
              slidesPerView={1}
              speed={500}
              grabCursor={true}
              autoplay={
                autoplay > 0
                  ? { delay: autoplay, disableOnInteraction: true }
                  : false
              }
            >
              {list.map((item, idx) => (
                <div className="slide" key={idx}>
                  <div
                    className="lead-image"
                    style={{
                      backgroundImage: `url(${imageSrc(item.image, "lead")})`,
                    }}
                  >
                    <div className="content">
                      <h1>{item.title}</h1>
                      {item.description !== "<p></p>" && (
                        <div
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Swiper>

            <button
              onClick={() => this.goNext()}
              className="swiper-button-next icon-arrow-right"
            />
            <button
              onClick={() => this.goPrev()}
              className="swiper-button-prev icon-arrow-left"
            />
          </div>
        </div>
      </Container>
    );
  }
}

LeadImageSlider.title = "Slider";
LeadImageSlider.category = "Media";
LeadImageSlider.dialogSize = "large";

LeadImageSlider.defaultProps = {
  list: [],
};

LeadImageSlider.defaults = {
  list: [
    {
      id: 1,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/1920x1080",
    },
    {
      id: 2,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/1920x1080",
    },
    {
      id: 3,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/1920x1080",
    },
  ],
  autoplay: 0,
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

LeadImageSlider.adminFields = (items, onChange, settings) => (
  <div>
    <TextInput
      label="Autoplay delay in ms (0 = disable)"
      onChange={(value) => onChange("autoplay", value)}
      value={settings.autoplay}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      items={items}
      template={{
        title: "Title",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/1920x1080",
      }}
      primaryField="title"
      onChangeList={onChange}
    />
  </div>
);

export default LeadImageSlider;
