import React from "react";
import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const imageColumnVariant = {
  4: "8",
  6: "6",
  8: "4",
};

const TextAndImageWidget = ({
  variant,
  caption,
  image,
  text,
  textColumns = "4",
  containerSettings,
  filter = "no",
}) => (
  <Container settings={containerSettings}>
    <div className="text-and-image">
      <div className="container">
        <div className="row">
          {variant === "right" && (
            <div
              className={`col-${textColumns} text`}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          <div className={`col-${imageColumnVariant[textColumns]} image`}>
            {caption && <h4>{caption}</h4>}

            <div className="image-container">
              <Img src={image} variant="image" />
              {filter === "yes" && <div className="overlay" />}
            </div>
          </div>

          {variant === "left" && (
            <div
              className={`col-${textColumns}`}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      </div>
    </div>
  </Container>
);

TextAndImageWidget.title = "Text and image";
TextAndImageWidget.category = "Media";
TextAndImageWidget.primaryField = "variant";
TextAndImageWidget.dialogSize = "large";

TextAndImageWidget.defaults = {
  variant: "right",
  filter: "no",
  caption: "Image caption",
  textColumns: "4",
  image: "https://placehold.it/1920x1080",
  text: "<p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam corrupti sunt inventore at, eligendi quisquam modi ea earum asperiores libero quos reiciendis impedit placeat! Nesciunt sit consectetur vitae dignissimos deserunt.</p>",
  containerSettings: {},
};

TextAndImageWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["left", "Image on the left"],
      ["right", "Image on the right"],
    ],
  },
  filter: {
    type: "select",
    options: [
      ["no", "No"],
      ["yes", "Yes"],
    ],
  },
  textColumns: {
    type: "select",
    options: [
      ["4", "Four"],
      ["6", "Six"],
      ["8", "Eight"],
    ],
  },
  caption: { type: "text" },
  image: { type: "custom", component: ImagePicker },
  text: { type: "custom", component: RichText },
};

export default TextAndImageWidget;
