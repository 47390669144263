import { Container, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import React from "react";

const ImageWidget = ({ image, mobileImage, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="image">
      <div className="container">
        <Img src={image} variant="image" className="desktop" />
        {mobileImage && (
          <Img src={mobileImage} variant="square" className="mobile" />
        )}
      </div>
    </div>
  </Container>
);

ImageWidget.title = "Image";
ImageWidget.category = "Media";

ImageWidget.defaults = {
  image: "https://placehold.it/1920x1080",
  mobileImage: "https://placehold.it/540x540",
  containerSettings: {},
};

ImageWidget.adminFields = {
  image: { type: "custom", component: ImagePicker },
  mobileImage: { type: "custom", component: ImagePicker },
};

export default ImageWidget;
