import React from "react";
import jQuery from "jquery";
import { PageBuilder } from "@raketa-cms/raketa-cms";
import { MediaManagerContext } from "@raketa-cms/raketa-image-picker";
import LIBRARY from "./widgets";
import Layout from "./frontend/Layout";

import MediaManager from "./MediaManager";

const mediaManager = new MediaManager("/");

class AdminBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dirty: false,
      isLoading: false,
      page: props.page,
    };

    this.mediaManager = mediaManager;
  }

  componentDidMount() {
    // Check for changes and warn user
    jQuery(window).bind("beforeunload", () => {
      if (this.state.dirty) return "You have unsaved changes";
    });
  }

  componentDidCatch(error, info) {
    console.log("error", error, info);
  }

  handleChange() {
    this.setState({ dirty: true });
  }

  handleSave(page) {
    const { save_url } = this.props;

    this.setState({ isLoading: true }, () => {
      jQuery.ajax({
        method: "PATCH",
        beforeSend: (xhr) => {
          xhr.setRequestHeader(
            "X-CSRF-Token",
            jQuery('meta[name="csrf-token"]').attr("content")
          );
        },
        contentType: "application/json; charset=utf-8",
        url: save_url,
        data: JSON.stringify({ widgets: page.widgets }),
        dataType: "json",
        success: (resp) => {
          this.setState({ isLoading: false, dirty: false });
        },
        error: (xhr, err, e) => {
          alert("An error occured saving this page. ");
        },
      });
    });
  }

  render() {
    const { host, back_url, header_menu, footer_menu, section, gql_url } =
      this.props;
    const { isLoading, dirty, page } = this.state;

    return (
      <MediaManagerContext.Provider value={mediaManager}>
        <Layout
          headerMenu={header_menu}
          footerMenu={footer_menu}
          section={section}
          gqlUrl={gql_url}
          className="admin-builder"
        >
          <PageBuilder
            host={host}
            dirty={dirty}
            library={LIBRARY}
            adminLibrary={LIBRARY}
            themes={[
              ["none", "None"],
              ["light", "Light"],
              ["inverted", "Inverted"],
              ["gradient", "Gradient"],
            ]}
            page={page}
            mediaManager={this.mediaManager}
            onChange={(changedPage) => this.handleChange(changedPage)}
            onSave={(pageToSave) => this.handleSave(pageToSave)}
            onExit={() => (window.location.href = back_url)}
          />
        </Layout>
      </MediaManagerContext.Provider>
    );
  }
}

AdminBuilder.defaultProps = {
  host: "http://localhost:3000/",
};

export default AdminBuilder;
