import React from "react";
import {
  Container,
  List,
  TextInput,
  SelectMenu,
  LinkSettings,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import ColorPicker from "../pickers/ColorPicker";
import { RichText } from "@raketa-cms/raketa-rte";

const LinkCard = ({ inlineTheme, link, title, image, description }) => (
  <a href={link.link} className="card">
    {image && (
      <div className="image-wrapper">
        <div
          className="card-image"
          style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
        />

        <div className={`inline-theme ${inlineTheme} overlay`} />
      </div>
    )}

    <div className={`card-title inline-theme ${inlineTheme}`}>
      <h4 className="title">
        {title}
        <div className="red-arrow" />
      </h4>
    </div>

    {description !== "<p></p>" && (
      <div
        className="card-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </a>
);

const Card = ({ inlineTheme, title, image, description }) => (
  <div className="card">
    {image && (
      <div className="image-wrapper">
        <div
          className="card-image"
          style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
        />
      </div>
    )}

    <div className={`card-title inline-theme ${inlineTheme}`}>
      <h4>{title}</h4>
    </div>

    {description !== "<p></p>" && (
      <div
        className="card-description"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </div>
);

const Item = ({ link, ...props }) => (
  <div className="card">
    {link.link ? <LinkCard link={link} {...props} /> : <Card {...props} />}
  </div>
);

const variants = {
  2: 6,
  3: 4,
};

const CardsWidget = ({ variant, inlineTheme, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cards">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={`col-${variants[variant]}`}>
              <Item inlineTheme={inlineTheme} {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

CardsWidget.title = "Cards";
CardsWidget.category = "General";
CardsWidget.dialogSize = "large";

CardsWidget.defaults = {
  variant: 3,
  inlineTheme: "brown",
  list: [
    {
      id: 1,
      title: "Title",
      link: LinkSettings.defaults,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
    {
      id: 2,
      title: "Title",
      link: LinkSettings.defaults,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
    {
      id: 3,
      title: "Title",
      link: LinkSettings.defaults,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      image: "http://placehold.it/800x600",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

CardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <ColorPicker
      value={settings.inlineTheme}
      onChange={(value) => onChange("inlineTheme", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "Title",
        link: LinkSettings.defaults,
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
        image: "http://placehold.it/800x600",
      }}
    />
  </div>
);

export default CardsWidget;
