import React from "react";
import { Container, List, TextInput, SelectMenu } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const Item = ({ title, description }) => (
  <div className="intro-card">
    <h4>{title}</h4>
    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
  </div>
);

const variants = {
  2: 6,
  3: 4,
  4: 3,
};

const IntroCardsWidget = ({ variant, list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="intro-cards">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className={`col-${variants[variant]}`}>
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

IntroCardsWidget.title = "Intro cards";
IntroCardsWidget.category = "General";
IntroCardsWidget.dialogSize = "large";

IntroCardsWidget.defaults = {
  variant: 3,
  list: [
    {
      id: 1,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 2,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
    {
      id: 3,
      title: "Title",
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

IntroCardsWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[
        [2, 2],
        [3, 3],
        [4, 4],
      ]}
      value={settings.variant}
      onChange={(value) => onChange("variant", value)}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="title"
      template={{
        title: "Title",
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? Quisquam enim blanditiis deserunt cumque earum.</p>",
      }}
    />
  </div>
);

export default IntroCardsWidget;
