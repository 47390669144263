import React, { useState } from 'react';

const labelStyles = { padding: 0, margin: 0, boxSizing: 'border-box', fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"', fontSize: '16px', lineHeight: '1.5', color: '#000', fontWeight: 'normal', backgroundColor: 'transparent', display: 'block', marginBottom: '10px', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '12px', cursor: 'pointer', color: '#000', };
const colors = ["none", "brand", "brand-alt", "green", "brown", "orange", "lilac", "blue", "red", "navy", "brand-light"];

const ColorPicker = ({ value, onChange }) => {
  const [selectedColor, setColor] = useState(value);

  return (
    <div className="color-picker">
      <label htmlFor="" style={labelStyles}>Inline Theme</label>

      {colors.map(color => (
        <div
          key={color}
          className={`option ${color} ${selectedColor == color ? 'selected' : ''}`}
          onClick={() => {
            setColor(color);
            onChange(color);
          }}
        />
      ))}
    </div>
  );
}

export default ColorPicker;
