import React from 'react';
import { Container, TextInput, SelectMenu, Img } from '@raketa-cms/raketa-cms';
import { Query } from 'react-apollo';
import Swiper from 'react-id-swiper';

import { ARTICLES } from '../graphql/queries';

const Item = ({ title, slug, image, description }) => (
  <a href={`/news/${slug}`} className="article-card">
    <div className="image">
      <Img src={image} variant="card_horizontal" />
    </div>

    <h4 className="title">
      <div className="text">{title}</div>
      <div className="red-arrow" />
    </h4>

    {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
  </a>
);

class NewsFeedWidget extends React.Component {
  componentDidMount() {
    const { list, onChange } = this.props;
    if (onChange) this.swiper.on('slideChange', () => onChange(this.swiper, list));
  }

  goNext() {
    if (this.swiper) this.swiper.slideNext();
  }

  goPrev() {
    if (this.swiper) this.swiper.slidePrev();
  }

  render() {
    const { variant, limit, autoRotate, containerSettings } = this.props;

    return (
      <Container settings={containerSettings}>
        <div className="news-feed article-cards">
          <div className="container">
            <div className={variant}>
              <Query query={ARTICLES} variables={{ input: { limit: parseInt(limit, 10) } }}>
                {({ error, loading, data }) => {
                  if (error) return `Error! ${error.message}`;
                  if (loading) return 'Loading...';

                  const { articles } = data;

                  if (articles.length > 0) {
                    return (
                      <React.Fragment>
                        <Swiper
                          ref={node => { if (node) this.swiper = node.swiper }}
                          rebuildOnUpdate
                          pagination={{
                            el: '.swiper-pagination',
                            type: 'bullets',
                            clickable: true,
                          }}
                          loop
                          keyboard={true}
                          paginationClickable
                          spaceBetween={16}
                          slidesPerView={variant === 'shifted' ? 2 : 3}
                          grabCursor={true}
                          autoplay={autoRotate === 'off' ? false : { delay: autoRotate }}
                          breakpoints={{
                            599: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                            },
                          }}
                        >
                          {articles.map((article) =>
                            <div key={article.slug} className="slide">
                              <Item {...article} />
                            </div>
                          )}
                        </Swiper>

                        <button onClick={() => this.goNext()} className="swiper-button-next icon-arrow-right" />
                        <button onClick={() => this.goPrev()} className="swiper-button-prev icon-arrow-left" />
                      </React.Fragment>
                    )

                  } else {
                    return <h3>Add articles from admin</h3>
                  }
                }}
              </Query>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

NewsFeedWidget.title = 'News Feed';
NewsFeedWidget.category = 'General';
NewsFeedWidget.dialogSize = 'large';

NewsFeedWidget.defaults = {
  variant: 'shifted',
  limit: 4,
  autoRotate: 'off',
  containerSettings: {},
};

NewsFeedWidget.adminFields = (items, onChange, settings) => (
  <div>
    <SelectMenu
      label="Variant"
      options={[['shifted', 'Shifted'], ['full', 'Full-width']]}
      value={settings.variant}
      onChange={value => onChange('variant', value)}
    />

    <TextInput
      label="Limit"
      onChange={value => onChange('limit', value)}
      value={settings.limit}
    />

    <SelectMenu
      label="Autoplay"
      options={[['off', 'Off'], [3000, '3s'], [4000, '4s'], [5000, '5s'], [6000, '6s'], [7000, '7s'], [8000, '8s'], [9000, '9s'], [10000, '10s']]}
      value={settings.autoRotate}
      onChange={value => onChange('autoRotate', value)}
    />
  </div>
);

export default NewsFeedWidget;
