import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";

const OffceWidget = ({ title, address, map, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="offce">
      <div className="container">
        <div className="row">
          <div className="col-3">
            <h4>{title}</h4>
            <div dangerouslySetInnerHTML={{ __html: address }} />
          </div>

          <div className="col-9">
            <div dangerouslySetInnerHTML={{ __html: map }} />
          </div>
        </div>
      </div>
    </div>
  </Container>
);

OffceWidget.title = "Offce";
OffceWidget.category = "Media";
OffceWidget.primaryField = "title";
OffceWidget.dialogSize = "large";

OffceWidget.defaults = {
  title: "Office 1",
  address:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Molestias dignissimos iure rem quam. </p>",
  image: "https://placehold.it/800x800",
  map: "",
  containerSettings: {},
};

OffceWidget.adminFields = {
  title: { type: "text" },
  address: { type: "custom", component: RichText },
  map: { type: "textarea" },
};

export default OffceWidget;
