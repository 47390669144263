import React from "react";
import { Container, ButtonSettings } from "@raketa-cms/raketa-cms";
import { RichText } from "@raketa-cms/raketa-rte";
import Button from "../frontend/Button";
import ColorPicker from "../pickers/ColorPicker";

const CtaBarWidget = ({ title, button, inlineTheme, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cta-bar">
      <div className="container">
        <div className="row">
          <div className={button.link ? "col-10" : "col-12"}>
            <div className={`cta-bar-inner inline-theme ${inlineTheme}`}>
              <div
                className="cta-text"
                dangerouslySetInnerHTML={{ __html: title }}
              />

              {button.link && (
                <Button className="cta-button" settings={button} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

CtaBarWidget.title = "CTA bar";
CtaBarWidget.category = "General";
CtaBarWidget.primaryField = "title";
CtaBarWidget.dialogSize = "large";

CtaBarWidget.defaults = {
  title:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi maxime in optio id quo molestiae, esse veniam?</p>",
  button: ButtonSettings.defaults,
  inlineTheme: "none",
  containerSettings: {},
};

CtaBarWidget.adminFields = {
  title: { type: "custom", component: RichText },
  button: { type: "button" },
  inlineTheme: { type: "custom", component: ColorPicker },
};

export default CtaBarWidget;
