import React from "react";
import { Container, List, Img } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import { RichText } from "@raketa-cms/raketa-rte";

const Item = ({ image, description }) => (
  <div className="icons-and-description">
    <div className="icon-wrapper">
      <Img src={image} variant="original" />
    </div>
    {description && (
      <div
        className="description-wrapper"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    )}
  </div>
);

const IconsAndDescriptionWidget = ({ list, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="icons-and-descriptions">
      <div className="container">
        <div className="row">
          {list.map((item) => (
            <div key={item.id} className="col-4">
              <Item {...item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  </Container>
);

IconsAndDescriptionWidget.title = "Icons and description";
IconsAndDescriptionWidget.category = "General";
IconsAndDescriptionWidget.dialogSize = "large";

IconsAndDescriptionWidget.defaults = {
  list: [
    {
      id: 1,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? </p>",
      image: "http://placehold.it/100x100",
    },
    {
      id: 2,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? </p>",
      image: "http://placehold.it/100x100",
    },
    {
      id: 3,
      description:
        "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? </p>",
      image: "http://placehold.it/100x100",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <RichText
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />
  </div>
);

IconsAndDescriptionWidget.adminFields = (items, onChange, settings) => (
  <div>
    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="id"
      template={{
        description:
          "<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores distinctio ea non? </p>",
        image: "http://placehold.it/100x100",
      }}
    />
  </div>
);

export default IconsAndDescriptionWidget;
