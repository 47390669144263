import React from 'react';
import { Link } from '@raketa-cms/raketa-cms';

export default ({ footerOne, footerTwo, footerThree, footerSlim }) => (
  <div className="footer">
    <button onClick={() => window.scrollTo(0, 0)} type="button" className="back-to-top" />

    <div className="container">
      <div className="row">
        {footerOne && (
          <div className="col-3">
            {footerOne.name && <h4>{footerOne.name}</h4>}

            <ul>
              {footerOne.items.map(link => (
                <li key={link.id}>
                  <Link settings={link.link} />
                </li>
              ))}
            </ul>
          </div>
        )}

        {footerTwo && (
          <div className="col-3">
            {footerTwo.name && <h4>{footerTwo.name}</h4>}

            <ul>
              {footerTwo.items.map(link => (
                <li key={link.id}>
                  <Link settings={link.link} />
                </li>
              ))}
            </ul>
          </div>
        )}

        {footerThree && (
          <div className="col-3">
            {footerThree.name && <h4>{footerThree.name}</h4>}

            <ul>
              {footerThree.items.map(link => (
                <li key={link.id}>
                  <Link settings={link.link} />
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="col-3">
          <h4>Contact Us</h4>

          <p>
            <a href="/contacts">Bulgaria Headquarters</a><br />
            BIC IZOT, Building 1, Floor 3, Office 317<br />
            133, Tzarigradsko Chaussee Blvd.<br />
            Tel.: +359 2 9650650<br />
            <a href="mailto:office@smartcom.bg">office@smartcom.bg</a><br />
            <br />
            <a href="/contacts#turkey">Smartcom Turkey</a><br />
            <br />
            <a href="/contacts#uk">Smartcom UK</a>
          </p>
        </div>
      </div>

      {footerSlim &&
        <div className="row">
          <div className="col-9">
            <div className="slim-nav">
              <div className="logo-mono" />

              {footerSlim.name && <span>{footerSlim.name}</span>}

              <nav>
                {footerSlim.items.map(link => (
                  <Link key={link.id} settings={link.link} />
                ))}
              </nav>
            </div>
          </div>

          <div className="col-3">
            <div className="social-wrapper">
              <strong>Follow us</strong>
              <a href="https://www.linkedin.com/company/smartcom-bulgaria-ad/" target="_blank"><i className="icon icon-linkedin" /></a>
              <a href="https://twitter.com/smartcomgroup" target="_blank"><i className="icon icon-twitter" /></a>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
);
