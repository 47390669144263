import React from "react";
import { Container, EmbeddedVideo } from "@raketa-cms/raketa-cms";

const VideoWidget = ({ videoURL, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="video">
      <div className="container">
        <div className="video-wrapper">
          <EmbeddedVideo videoUrl={videoURL} />
        </div>
      </div>
    </div>
  </Container>
);

VideoWidget.title = "Video";
VideoWidget.category = "Media";
VideoWidget.primaryField = "videoURL";

VideoWidget.defaults = {
  videoURL: "https://www.youtube.com/watch?v=7Glc2mHLPRM",
  containerSettings: {},
};

VideoWidget.adminFields = {
  videoURL: { type: "text" },
};

export default VideoWidget;
