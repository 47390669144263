import React from 'react';

export default ({ options, prompt = 'Please select...', value, onChange, ...props }) => (
  <select value={value} onChange={e => onChange(e.target.value, e)} {...props}>
    {prompt && (
      <option value="">{prompt}</option>
    )}

    {options.map((o) => (
      <option key={o[0]} value={o[0]}>{o[1]}</option>
    ))}
  </select>
);
