import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  List,
  TextInput,
  LinkSettings,
  imageSrc,
} from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import ColorPicker from "../pickers/ColorPicker";

const smoothAnimation = (from, to, time, callback) => {
  const start = new Date().getTime();

  const timer = setInterval(() => {
    const step = Math.min(1, (new Date().getTime() - start) / time);
    const positionY = from + step * (to - from);

    top.window.scroll(0, positionY);

    if (step === 1) {
      clearInterval(timer);
      callback();
    }
  }, 25);

  top.window.scroll(0, from);
};

const setScrollAnimation = (url, event, clickedTarget = event.target) => {
  event.preventDefault();
  const targetId = url.charAt("#") === "#" ? url.substr(1) : url;
  const targetElement = document.getElementById(targetId);

  const menuNode =
    clickedTarget.parentElement.parentElement.parentElement.parentElement;

  const destinationOffcet =
    window.pageYOffset < menuNode.offsetTop
      ? // 50 is magic number find how to fix it
        targetElement.offsetTop - 50 * 2
      : targetElement.offsetTop - 50;

  smoothAnimation(window.pageYOffset, destinationOffcet, 300, () => {
    if (history.pushState) {
      history.pushState(null, null, `#${targetId}`);
    } else {
      location.hash = `#${targetId}`;
    }
  });
};

const getOffsetTop = (elem) => {
  // Set our distance placeholder
  let distance = 0;

  // Loop up the DOM
  if (elem.offsetParent) {
    do {
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }

  // Return our distance
  return distance < 0 ? 0 : distance;
};

const getCurrent = (arr, currentOffset) => {
  // 66 is fixed nav height
  currentOffset = currentOffset + 66;

  for (let i = 0; i < arr.length - 1; i++) {
    if (i === arr.length - 2 && currentOffset >= arr[i + 1].topOffset) {
      return arr[i + 1];
    }

    if (
      arr[i].topOffset < currentOffset &&
      arr[i + 1].topOffset > currentOffset
    ) {
      return arr[i];
    }
  }
};

const setCurrentClass = (targetSections, nav) => {
  const pageOffset = window.pageYOffset;

  const elementsTopOffcet = targetSections.map((section) => ({
    section,
    topOffset: getOffsetTop(section),
  }));
  const current = getCurrent(elementsTopOffcet, pageOffset);

  if (current) {
    const currentNavElement = nav.querySelector(
      `a[href^="#${current.section.getAttribute("id")}"]`
    );

    const navigationLink = nav.querySelector("a.current");
    if (navigationLink) navigationLink.classList.remove("current");

    currentNavElement.classList.add("current");
  }
};

const Item = ({ link, image, navigation }) => (
  <a
    onClick={(e) => setScrollAnimation(link.link, e, navigation)}
    href={link.link}
    id={link.id}
    className={`navigation-link ${image ? "has-image" : ""}`}
  >
    <div
      className="link-background"
      style={{
        backgroundImage: image ? `url(${imageSrc(image, "lead")})` : "",
      }}
    />
    <div className="link-text">{link.label}</div>
  </a>
);

const NavigationWidget = ({ inlineTheme, title, list, containerSettings }) => {
  const [isFixed, setFixed] = useState(false);
  const navigation = useRef(null);
  const sectionLinks = list
    .map((item) => item.link.link)
    .filter((link) => link.startsWith("#"));

  useEffect(() => {
    const nav = navigation.current;
    const sticky = nav.offsetTop;
    const targetSections = [];

    if (sectionLinks.length > 0) {
      sectionLinks.forEach((elementId) => {
        if (elementId !== "#") {
          const target = document.querySelector(elementId);
          if (target) targetSections.push(target);
        }
      });
    }

    const scrollCallBack = window.addEventListener("scroll", () => {
      if (targetSections.length > 0) setCurrentClass(targetSections, nav);

      if (window.pageYOffset > sticky) {
        nav.classList.add("fixed");
        setFixed(true);
      } else {
        nav.classList.remove("fixed");
        setFixed(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, []);

  return (
    <Container settings={containerSettings}>
      <div ref={navigation} className={`navigations it-${inlineTheme}`}>
        <div className="container">
          <div className="row">
            {isFixed && (
              <div className="col-3">
                <div className="title">{title}</div>
              </div>
            )}

            <div className={isFixed ? "col-9" : "col-12"}>
              <div className="navigation-wrapper">
                {list.map((item) => (
                  <div key={item.id} className="navigation-item">
                    <Item {...item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

NavigationWidget.title = "Navigation";
NavigationWidget.category = "General";
NavigationWidget.dialogSize = "large";

NavigationWidget.defaults = {
  inlineTheme: "none",
  title: "Title",
  list: [
    {
      id: 1,
      link: LinkSettings.defaults,
      image: "http://placehold.it/400x300",
    },
    {
      id: 2,
      link: LinkSettings.defaults,
      image: "http://placehold.it/400x300",
    },
    {
      id: 3,
      link: LinkSettings.defaults,
      image: "http://placehold.it/400x300",
    },
  ],
  containerSettings: {},
};

const ListItem = ({ settings, onChangeItem }) => (
  <div>
    <ImagePicker
      label="Image"
      onChange={(value) => onChangeItem("image", value)}
      value={settings.image}
    />

    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

NavigationWidget.adminFields = (items, onChange, settings) => (
  <div>
    <ColorPicker
      value={settings.inlineTheme}
      onChange={(value) => onChange("inlineTheme", value)}
    />

    <TextInput
      label="Title"
      onChange={(value) => onChange("title", value)}
      value={settings.title}
    />

    <List
      listItem={(settings, onChangeItem) => (
        <ListItem settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={onChange}
      items={items}
      primaryField="id"
      template={{
        link: LinkSettings.defaults,
        image: "http://placehold.it/400x300",
      }}
    />
  </div>
);

export default NavigationWidget;
