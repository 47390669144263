import React, { useState } from "react";
import { Link } from "@raketa-cms/raketa-cms";
import Search from "./Search";

const getOpen = (arr, key) => {
  const idx = arr.indexOf(key);

  if (idx !== -1) {
    return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
  } else {
    return [...arr, key];
  }
};

const getKey = (item) => `${item.id}-${item.link.label}`;

export default ({ headerMenu, section, open, onOpen, ...props }) => {
  const [main, setMain] = useState(false);

  return (
    <div className={`header ${main ? "opened" : ""}`} {...props}>
      <div className="container">
        <div className="search-wrapper">
          <Search search_url="/search" q="" placeholder="Type something..." />
        </div>

        <div className="header-inner">
          <div className="logo-wrapper">
            <h1 className="logo">
              <a href="/">Smartcom</a>
            </h1>

            <button
              type="button"
              className="mobile-trigger"
              onClick={() => setMain(!main)}
            ></button>
          </div>

          <nav>
            {headerMenu.items.map((tl) => (
              <div
                key={tl.id}
                className={`nav-item ${
                  tl.submenus.length > 0 ? "has-submenu" : ""
                } ${open.indexOf(getKey(tl)) !== -1 ? "opened" : ""} ${
                  tl.link.className === section ? "current" : ""
                }`}
              >
                <a
                  href={tl.link.link}
                  id={tl.link.id}
                  onClick={(e) => {
                    if (tl.submenus.length > 0) e.preventDefault();
                    onOpen(getOpen(open, getKey(tl)));
                  }}
                >
                  {tl.link.label}
                </a>

                {tl.submenus.length > 0 && (
                  <div className="sub-menu">
                    <div className="container">
                      <div className="row">
                        <div className="col-3">
                          <h3>{tl.link.label}</h3>
                        </div>

                        {tl.submenus.map((sm) => (
                          <div
                            className={
                              tl.submenus.length > 1 ? "col-3" : "col-9"
                            }
                            key={sm.name}
                          >
                            {tl.submenus.length > 1 && <h4>{sm.name}</h4>}

                            <ul>
                              {sm.items.map((smi) => (
                                <li key={smi.id}>
                                  <Link settings={smi.link} />
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};
