import React from "react";
import { Container } from "@raketa-cms/raketa-cms";
import DownloadForm from "../frontend/DownloadForm";

const SingleDownloadWidget = ({
  icon,
  title,
  url,
  recaptchaPublicKey,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="download-files">
      <DownloadForm
        icon={icon}
        title={title}
        url={url}
        recaptchaPublicKey={recaptchaPublicKey}
      />
    </div>
  </Container>
);

SingleDownloadWidget.title = "Single download";
SingleDownloadWidget.category = "General";
SingleDownloadWidget.primaryField = "title";
SingleDownloadWidget.dialogSize = "large";

SingleDownloadWidget.defaults = {
  icon: "http://placehold.it/64x64",
  title: "File",
  url: "#",
  recaptchaPublicKey: "",
  containerSettings: {},
};

SingleDownloadWidget.adminFields = {
  icon: { type: "image" },
  title: { type: "text" },
  url: { type: "text", label: "Form URL" },
  recaptchaPublicKey: { type: "text" },
};

export default SingleDownloadWidget;
