import React from "react";
import { Container, LinkSettings, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";
import ColorPicker from "../pickers/ColorPicker";

const TitleBar = ({ inlineTheme, title, arrow = false }) => (
  <div className={`title-bar inline-theme ${inlineTheme}`}>
    <h3 className="title">
      {title}
      {arrow && <div className="red-arrow" />}
    </h3>
  </div>
);

const LinkSection = ({ image, link, title, inlineTheme }) => (
  <a href={link.link} className="section-lead">
    <div
      className="image"
      style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
    />
    <div className={`overlay inline-theme ${inlineTheme}`} />
    <TitleBar arrow inlineTheme={inlineTheme} title={title} />
  </a>
);

const Section = ({ image, title, inlineTheme }) => (
  <div className="section-lead no-link">
    <div
      className="image"
      style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
    />
    <div className={`overlay inline-theme ${inlineTheme}`} />
    <TitleBar inlineTheme={inlineTheme} title={title} />
  </div>
);

const SectionLeadWidget = ({
  inlineTheme,
  title,
  link,
  image,
  containerSettings,
}) => (
  <Container settings={containerSettings}>
    <div className="container">
      {link.link ? (
        <LinkSection
          image={image}
          link={link}
          title={title}
          inlineTheme={inlineTheme}
        />
      ) : (
        <Section image={image} title={title} inlineTheme={inlineTheme} />
      )}
    </div>
  </Container>
);

SectionLeadWidget.title = "Section lead";
SectionLeadWidget.category = "Media";
SectionLeadWidget.primaryField = "title";
SectionLeadWidget.dialogSize = "large";

SectionLeadWidget.defaults = {
  inlineTheme: "none",
  title: "Section",
  link: LinkSettings.defaults,
  image: "https://placehold.it/1920x1080",
  containerSettings: {},
};

SectionLeadWidget.adminFields = {
  inlineTheme: { type: "custom", component: ColorPicker },
  title: { type: "text" },
  link: { type: "custom", component: LinkSettings },
  image: { type: "custom", component: ImagePicker },
};

export default SectionLeadWidget;
