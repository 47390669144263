import React from 'react';
import Autocomplete from 'react-autocomplete';
import jQuery from 'jquery';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      q: props.q || '',
      isVisible: false,
    };
  }

  handleProductSearch(q) {
    const { search_url } = this.props;

    this.setState({ q, articles: [] }, () => {
      if (q.length < 3) return;
      jQuery.get(search_url, { q }, articles => this.setState({ articles }), 'json');
    });
  }

  handleGoToArticle(articleName) {
    const { articles, items } = this.state;

    const selectedArticle = articles.find(item => item.title === articleName);

    if (typeof window !== 'undefined') window.location.href = selectedArticle.url;
  }

  render() {
    const { search_url, placeholder } = this.props;
    const { q, articles, isVisible } = this.state;

    const menuStyle = {
      border: '1px solid #6a6f76',
      background: '#fff',
      position: 'absolute',
      top: 'auto',
      overflow: 'auto',
      left: '40px',
      right: 0,
      zIndex: '5',
    };

    return (
      <div className="search">
        <i
          onClick={() => this.setState({ isVisible: !isVisible })}
          className="icon icon-search"
        />

        {isVisible && (
          <form method="get" className="form" action={search_url}>
            <Autocomplete
              getItemValue={item => item.title}
              items={articles}
              renderMenu={(items, value, style) => (
                <div className="menu-wrapper" style={{ ...style }}>
                  {(items.length > 0) && (
                    <div className="menu" >
                      {items.slice(0, 6)}
                    </div>
                  )}
                </div>
              )}
              renderItem={(item, isHighlighted) =>
                <div style={{ padding: '1em', cursor: 'pointer', background: isHighlighted ? 'lightgray' : 'white' }} key={item.url} >
                  {item.title} {item.faq_category && <small className="category-name"> - {item.faq_category.name}</small>}
                </div>
              }
              value={q}
              onChange={e => this.handleProductSearch(e.target.value)}
              onSelect={articleName => this.handleGoToArticle(articleName)}
              inputProps={{ placeholder, className: 'faq-search-input' }}
              wrapperStyle={{ display: 'inline-block', width: '100%' }}
              menuStyle={menuStyle}
            />

            {q && (
              <div
                onClick={() => this.setState({ q: '' })}
                className="clear-text"
              />
            )}

            <input type="hidden" name="q" value={q} />
          </form>
        )}
      </div>
    );
  }
}


export default Search;
