import React from "react";
import { Container, Img, imageSrc } from "@raketa-cms/raketa-cms";
import { ImagePicker } from "@raketa-cms/raketa-image-picker";

const ProductHeroWidget = ({ variant, logo, image, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="product-hero">
      <div className="container">
        <div className="row">
          {variant === "right" && (
            <div className="col-8">
              <div
                className="product-image"
                style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
              />
            </div>
          )}

          <div className="col-4">
            <div className="logo-wrapper">
              <Img src={logo} variant="original" />
            </div>
          </div>

          {variant === "left" && (
            <div className="col-8">
              <div
                className="product-image"
                style={{ backgroundImage: `url(${imageSrc(image, "lead")})` }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  </Container>
);

ProductHeroWidget.title = "Product hero";
ProductHeroWidget.category = "Media";
ProductHeroWidget.primaryField = "variant";
ProductHeroWidget.dialogSize = "large";

ProductHeroWidget.defaults = {
  variant: "right",
  logo: "https://placehold.it/200x200",
  image: "https://placehold.it/1920x1080",
  containerSettings: {},
};

ProductHeroWidget.adminFields = {
  variant: {
    type: "select",
    options: [
      ["left", "Logo on left"],
      ["right", "Logo on right"],
    ],
  },
  logo: { type: "custom", component: ImagePicker },
  image: { type: "custom", component: ImagePicker },
};

export default ProductHeroWidget;
