import React from "react";
import { Container } from "@raketa-cms/raketa-cms";

const NavigationTitleWidget = ({ title, link, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="navigation-title">
      <div className="container">
        <div className="row">
          <div className="col-3 title-wrapper">
            <h5 className="title">
              {link && <a className="red-arrow" href={link} />}
              {title}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </Container>
);

NavigationTitleWidget.title = "Navigation title";
NavigationTitleWidget.category = "General";
NavigationTitleWidget.primaryField = "title";

NavigationTitleWidget.defaults = {
  title: "Title",
  link: "",
  containerSettings: {},
};

NavigationTitleWidget.adminFields = {
  title: { type: "text" },
  link: { type: "text" },
};

export default NavigationTitleWidget;
