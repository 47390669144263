import React from "react";
import { Container, Link, LinkSettings } from "@raketa-cms/raketa-cms";
import ColorPicker from "../pickers/ColorPicker";
import { RichText } from "@raketa-cms/raketa-rte";

const CtaLinkWidget = ({ title, link, inlineTheme, containerSettings }) => (
  <Container settings={containerSettings}>
    <div className="cta-link">
      <div className="container">
        <Link settings={link}>
          <div className={`cta-bar-inner inline-theme ${inlineTheme}`}>
            <div
              className="cta-text"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div className="red-arrow" />
          </div>
        </Link>
      </div>
    </div>
  </Container>
);

CtaLinkWidget.title = "CTA Link";
CtaLinkWidget.category = "General";
CtaLinkWidget.primaryField = "title";
CtaLinkWidget.dialogSize = "large";

CtaLinkWidget.defaults = {
  title:
    "<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi maxime in optio id quo molestiae, esse veniam?</p>",
  link: LinkSettings.defaults,
  inlineTheme: "none",
  containerSettings: {},
};

CtaLinkWidget.adminFields = {
  title: { type: "custom", component: RichText },
  link: { type: "custom", component: LinkSettings },
  inlineTheme: { type: "custom", component: ColorPicker },
};

export default CtaLinkWidget;
