import React, { useState } from "react";
import { Img } from "@raketa-cms/raketa-cms";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "../Loader";

const post = (url, params = {}, opts = {}) =>
  fetch(
    url,
    Object.assign(
      {
        method: "POST",
        credentials: "same-origin",
        body: JSON.stringify(params),
      },
      opts
    )
  );

const Input = ({
  type = "text",
  label,
  value,
  onChange,
  required = false,
  ...props
}) => (
  <div className="form-group">
    <label className="control-label">{label}</label>
    <input
      type={type}
      className="form-control"
      required={required}
      value={value}
      onChange={(e) => onChange(e.target.value, e)}
      {...props}
    />
  </div>
);

const Checkbox = ({ children, value, onChange }) => (
  <div className="checkbox">
    <label>
      <input
        type="checkbox"
        value="1"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />

      <div className="checkbox-label">{children}</div>
    </label>
  </div>
);

const Form = ({ url, recaptchaPublicKey, onSubmit, onCancel }) => {
  const [form, setForm] = useState({
    submitting: false,
    recaptchaValue: "",
    name: "",
    email: "",
    company: "",
    position: "",
    privacy: false,
    messages: false,
  });

  const valid =
    form.name.trim() !== "" &&
    form.email.trim() !== "" &&
    form.company.trim() !== "" &&
    form.position.trim() !== "" &&
    form.privacy;

  if (form.submitting) {
    return (
      <div>
        <Loader stroke="#000" />
        <p>
          Thank you! Please check your inbox, we just sent you an email with
          your download.{" "}
        </p>
      </div>
    );
  }

  return (
    <form
      action={url}
      method="post"
      onSubmit={(e) => {
        e.preventDefault();

        if (!valid) {
          alert("Please fill in all required fields");
          return;
        }

        const body = new FormData();
        body.append("data[name]", form.name);
        body.append("data[email]", form.email);
        body.append("data[company]", form.company);
        body.append("data[position]", form.position);
        body.append("data[messages]", form.messages);
        body.append("data[privacy]", form.privacy);

        if (recaptchaPublicKey) {
          body.append("g-recaptcha-response", form.recaptchaValue);
        }

        setForm(Object.assign({}, form, { submitting: true }));

        post(url, {}, { body })
          .then((r) => r.json())
          .then((result) => {
            setTimeout(() => {
              setForm(
                Object.assign({}, form, {
                  submitting: false,
                  name: "",
                  email: "",
                  company: "",
                  position: "",
                  privacy: false,
                  messages: false,
                })
              );
              onSubmit();
            }, 5000);
          });
      }}
    >
      <div className="row">
        <div className="col-12">
          <div className="spacing-bottom">
            <p>To download this document, please fill out the form below. </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Input
            name="data[name]"
            label="Name"
            required
            value={form.name}
            onChange={(name) => setForm(Object.assign({}, form, { name }))}
          />
        </div>
        <div className="col-6">
          <Input
            type="email"
            name="data[email]"
            label="E-mail"
            required
            value={form.email}
            onChange={(email) => setForm(Object.assign({}, form, { email }))}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-6">
          <Input
            name="data[company]"
            label="Company"
            required
            value={form.company}
            onChange={(company) =>
              setForm(Object.assign({}, form, { company }))
            }
          />
        </div>
        <div className="col-6">
          <Input
            name="data[position]"
            label="Position"
            required
            value={form.position}
            onChange={(position) =>
              setForm(Object.assign({}, form, { position }))
            }
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Checkbox
            value={form.privacy}
            onChange={(privacy) =>
              setForm(Object.assign({}, form, { privacy }))
            }
          >
            I am familiar with{" "}
            <a href="/documents-repository/privacy-policy">
              Smartcom's Privacy Policy
            </a>
          </Checkbox>

          <Checkbox
            value={form.messages}
            onChange={(messages) =>
              setForm(Object.assign({}, form, { messages }))
            }
          >
            I agree to receive marketing communication from Smartcom (optional)
          </Checkbox>

          {recaptchaPublicKey && (
            <div className="spacing-bottom">
              <ReCAPTCHA
                sitekey={recaptchaPublicKey}
                onChange={(value) => {
                  setForm({ ...form, recaptchaValue: value });
                }}
              />
            </div>
          )}

          <button type="button" className="btn-secondary" onClick={onCancel}>
            Cancel
          </button>

          <button
            type="submit"
            className="btn-primary"
            disabled={recaptchaPublicKey && !form.recaptchaValue}
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default ({ title, url, icon, recaptchaPublicKey }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`download-file ${open ? "open" : ""}`}>
      <div className="cta-bar">
        <div className="container">
          <div className="row">
            <div className="col-10">
              <div className="cta-bar-inner inline-theme">
                <div className="download-title">
                  <div className="cta-text">
                    <Img src={icon} variant="original" className="file-icon" />
                    <h5>{title}</h5>
                  </div>

                  {!open && (
                    <button
                      type="button"
                      className="btn-secondary cta-button toggle-form"
                      onClick={() => setOpen(!open)}
                    >
                      Download
                    </button>
                  )}
                </div>

                <div className="download-form">
                  <Form
                    url={url}
                    onSubmit={() => setOpen(false)}
                    onCancel={() => setOpen(false)}
                    recaptchaPublicKey={recaptchaPublicKey}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
