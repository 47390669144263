import React, { useState } from 'react';
import Header from './Header';

import GraphqlProvider from '../providers/GraphqlProvider';
import Footer from './Footer';

function hasParent(element, classname) {
  if (element.className && element.className.split(' ').indexOf(classname) >= 0) return true;
  return element.parentNode && hasParent(element.parentNode, classname);
}

const handleClickOutside = (e, callback) => {
  if (!hasParent(e.target, 'sub-menu')) callback();
};

export default ({ children, className, headerMenu, gqlUrl, section, ...props }) => {
  const [open, setOpen] = useState([]);

  return (
    <div className={className} onClick={(e) => handleClickOutside(e, () => (open.length > 0) ? setOpen([]) : null)}>
      <GraphqlProvider gqlHost={gqlUrl}>
        <Header
          headerMenu={headerMenu}
          section={section}
          open={open}
          onOpen={(newOpen) => setOpen(newOpen)}
        />

        {children}

        <Footer
          footerOne={props.footerMenu.footer_1}
          footerTwo={props.footerMenu.footer_2}
          footerThree={props.footerMenu.footer_3}
          footerSlim={props.footerMenu.footer_slim}
        />
      </GraphqlProvider>
    </div>
  );
}
